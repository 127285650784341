<template>
    <div>
      <main>
        <!-- 麵包屑 -->
        <section class="container" data-aos="fade-down"  data-aos-delay="500">
          <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb" class="badge">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="unintended-2">我意外懷孕了</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="unintended-3-1">我想要生下來</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="parenting-4-1">育兒預備</router-link></li>
              <li class="breadcrumb-item active" aria-current="page"><router-link to="">寶寶問題</router-link></li>
            </ol>
          </nav>
        </section>

        <!-- 主要區塊 -->
        <section class="container step-rwd">
          <div class="step">
            <!-- 裝飾用圖片 -->
            <img class="step-circle" src="../../assets/image/front/image_circleword.svg">
            <img class="image1" src="../../assets/image/front/page02_image1.svg">
            <img class="image2" src="../../assets/image/front/page02_image2.svg">
            <img class="image3" src="../../assets/image/front/page02_image3.svg">
            <!-- 結束 -->
            <div class="title-box">
              <p class="step-text" data-aos="fade-up" >ANSEWR</p>
              <h2 class="step-title" data-aos="fade-up">選擇・答案</h2>
              <img class="title-line" src="../../assets/image/front/choose_title_line.svg" data-aos="fade-up" >
              <p class="step-sologan" data-aos="fade-up" >為自己所想，為自己所選。</p>
            </div>
            <div class="container step-box">
              <div class="step-box-inner">
                <div class="box-title" data-aos="fade-up">
                  <h2>｜寶寶問題｜</h2>
                </div>
                <div class="select-menu">
                  <img class="mb-60" data-aos="fade-up" src='../../assets/image/front/page02_pic3.jpg'>
                  <hr data-aos="fade-up">
                    <!-- 選項開始 -->
                    <ul class="select-item container-inner mt-60">
                      <li data-aos="fade-up">
                        <h3>準備懷孕 怎麼吃才能營養又健康？</h3>
                        <a class="mb-60 d-block text-primary" href="https://starthealthy.nestle.com.tw/1000days/section/1/view/96" target="_blank">查看文章</a>
                      </li>
                      <li data-aos="fade-up">
                        <h3>準備懷孕營養均衡先打底！</h3>
                        <a class="mb-60 d-block text-primary" href="https://starthealthy.nestle.com.tw/1000days/section/1/view/168" target="_blank">查看文章</a>
                      </li>
                      <li data-aos="fade-up">
                        <h3>新生命報到！寶寶在做什麼呢</h3>
                        <a class="mb-60 d-block text-primary" href="https://starthealthy.nestle.com.tw/1000days/section/1/view/169" target="_blank">查看文章</a>
                      </li>
                       <li data-aos="fade-up">
                        <h3>6大懷孕初期症狀出現，準備當媽咪？！懷孕初期症狀完整解析</h3>
                        <a class="mb-60 d-block text-primary" href="https://starthealthy.nestle.com.tw/1000days/section/1/view/170" target="_blank">查看文章</a>
                      </li>
                      <li data-aos="fade-up">
                        <h3>體重該增加多少呢?</h3>
                        <a class="mb-60 d-block text-primary" href="https://starthealthy.nestle.com.tw/1000days/section/1/view/171" target="_blank">查看文章</a>
                      </li>
                      <li data-aos="fade-up">
                        <h3>解除孕早期不適：倦怠疲勞</h3>
                        <a class="mb-60 d-block text-primary" href="https://starthealthy.nestle.com.tw/1000days/section/1/view/172" target="_blank">查看文章</a>
                      </li>
                      <li data-aos="fade-up">
                        <h3>孕吐嚴重怎麼辦？五招緩解懷孕初期的孕吐症狀</h3>
                        <a class="mb-60 d-block text-primary" href="https://starthealthy.nestle.com.tw/1000days/section/1/view/173" target="_blank">查看文章</a>
                      </li>
                      <li data-aos="fade-up">
                        <h3>了解孕早期不適：頻尿</h3>
                        <a class="mb-60 d-block text-primary" href="https://starthealthy.nestle.com.tw/1000days/section/1/view/174" target="_blank">查看文章</a>
                      </li>
                      <li data-aos="fade-up">
                        <h3>懷孕初期飲食規劃：完整懷孕飲食餐單，讓孕媽咪吃出營養又健康</h3>
                        <a class="mb-60 d-block text-primary" href="https://starthealthy.nestle.com.tw/1000days/section/1/view/175" target="_blank">查看文章</a>
                      </li>
                      <li data-aos="fade-up">
                        <h3>葉酸推薦從懷孕初期就開始補充！分享營養師推薦的葉酸挑選原則</h3>
                        <a class="mb-60 d-block text-primary" href="https://starthealthy.nestle.com.tw/1000days/section/1/view/176" target="_blank">查看文章</a>
                      </li>
                      <li data-aos="fade-up">
                        <h3>DHA是孕婦必備營養?!學會DHA補充方法，綻放寶寶發展力</h3>
                        <a class="mb-60 d-block text-primary" href="https://starthealthy.nestle.com.tw/1000days/section/1/view/177" target="_blank">查看文章</a>
                      </li>
                      <li data-aos="fade-up">
                        <h3>孕媽咪的預防過敏飲食建議</h3>
                        <a class="mb-60 d-block text-primary" href="https://starthealthy.nestle.com.tw/1000days/section/1/view/178" target="_blank">查看文章</a>
                      </li>
                      <li data-aos="fade-up">
                        <h3>懷孕初期出血咖啡色是異狀？詳解9大懷孕初期出血原因</h3>
                        <a class="mb-60 d-block text-primary" href="https://starthealthy.nestle.com.tw/1000days/section/1/view/469" target="_blank">查看文章</a>
                      </li>
                      <li data-aos="fade-up">
                        <h3>產前憂鬱症症狀有哪些？試著讓家人陪伴妳的憂鬱症</h3>
                        <a class="mb-60 d-block text-primary" href="https://starthealthy.nestle.com.tw/1000days/section/1/view/470" target="_blank">查看文章</a>
                      </li>
                      <li data-aos="fade-up">
                        <h3>懷孕週期怎麼算？計算懷孕週期，輕鬆推估預產期！</h3>
                        <a class="mb-60 d-block text-primary" href="https://starthealthy.nestle.com.tw/1000days/section/1/view/472" target="_blank">查看文章</a>
                      </li>
                      <li data-aos="fade-up">
                        <h3>懷孕初期分泌物多正常嗎？3招改善懷孕分泌物困擾</h3>
                        <a class="mb-60 d-block text-primary" href="https://starthealthy.nestle.com.tw/1000days/section/1/view/473" target="_blank">查看文章</a>
                      </li>
                        <li data-aos="fade-up">
                          <p>
                           參考來源：<a class="fs-5 text-danger" href="https://starthealthy.nestle.com.tw/1000days/section/1" target="_blank">雀巢一千天營養計畫</a>
                          </p>
                        </li>

                    </ul>
                    <!-- 選項結束 -->
                    <hr data-aos="fade-up">
                    <div data-aos="fade-up">
                      <a class="goBack" onclick="history.back()" value="回到上一頁">回到上一頁</a>
                      <a class="goBack" href="#">返回首頁</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Consult></Consult>
        <Circle></Circle>
      </main>
    </div>
</template>
<script>
import Consult from '../../components/Consult.vue'
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  components: {
    Consult
  }
}
</script>
